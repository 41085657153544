import { API_AXIOS } from "../../_interceptors/interceptor";

// Employee

export const _employeeListApi = async (payload) => {
  const { searchValue, page } = payload
  try {
    const { data } = await API_AXIOS({
      url: `/admins?searchValue=${searchValue}&page=${page}`,
      method: 'GET',
      data: payload
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _normalemployeeListApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admins?list=true&searchValue=${payload?.searchValue || ''}`,
      method: 'GET',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};


export const _employeeViewApi = async (agentId) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admin/${agentId}`,
      method: 'GET',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _employeeEditApi = async ({ adminId, form }) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admin/${adminId}`,
      method: 'PUT',
      data: form
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _employeeDeleteApi = async (adminId) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admin/${adminId}`,
      method: 'DELETE',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _adminIsStatusApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admin/status`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _adminIsUpdataApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admin/update-status`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};


export const _adminIsDeleteApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/admin/delete-status`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _adminExportpi = async () => {
  try {
    const { data } = await API_AXIOS({
      url: `/export/admins`,
      method: 'GET',
      responseType: 'blob',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};



// Agent

export const _agentListApi = async (payload) => {
  const { searchValue, page } = payload

  try {
    const { data } = await API_AXIOS({
      url: `/agents?searchValue=${searchValue}&page=${page}`,
      method: 'GET',
      data: payload
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _agentJustListApi = async (payload) => {

  try {
    const { data } = await API_AXIOS({
      url: `/agents?list=true&searchValue=${payload?.searchValue || ''}`,
      method: 'GET',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _agentEditApi = async ({ agentId, form }) => {
  try {
    const { data } = await API_AXIOS({
      url: `/agent/${agentId}`,
      method: 'PUT',
      data: form
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _agentViewApi = async (agentId) => {
  try {
    const { data } = await API_AXIOS({
      url: `/agent/${agentId}`,
      method: 'GET',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};

export const _agentDeleteApi = async (agentId) => {
  try {
    const { data } = await API_AXIOS({
      url: `/agent/${agentId}`,
      method: 'DELETE',
    });

    return data;
  } catch (error) {
    console.error("AdMIN API call error:", error);
    throw error;
  }
};


export const _agentIsStatusApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/agent/status`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error) {
    console.error(" API call error:", error);
    throw error;
  }
};

export const _agentIsUpdataApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/agent/update-status`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error) {
    console.error(" API call error:", error);
    throw error;
  }
};


export const _agentIsDeleteApi = async (payload) => {
  try {
    const { data } = await API_AXIOS({
      url: `/agent/delete-status`,
      method: 'POST',
      data: payload
    });

    return data;
  } catch (error) {
    console.error(" API call error:", error);
    throw error;
  }
};