import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth.user);
  
  if (!auth?.authToken) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;