import { combineReducers } from 'redux';
import authReducer from './_slice/authReducer';
import userReducer from './_slice/userReducer';
import contractReducer from './_slice/contractReducer';
import notificationReducer from './_slice/notificationReducer';
import commissionReducer from './_slice/commissionReducer';
import chatReducer from './_slice/chatReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  contract: contractReducer,
  notification: notificationReducer,
  commission: commissionReducer,
  chat: chatReducer
});
export default rootReducer;