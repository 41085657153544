import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loadable from "./components/Loadable";
import { lazy } from "react";
import PublicRoute from "./pages/PublicRoute/index.js";
import PublicLayout from "./components/layout/PublicLayout.js";
import ProtectedRoute from "./pages/ProtectedRoute/index.js";
import Esign from "./esign/Esign.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DesktopWarning from "./DesktopWarning.js";
import { login } from "./_redux/_slice/authReducer.js";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { _agentViewApi, _employeeViewApi } from "./pages/User/api.js";

const Login = Loadable(lazy(() => import("./pages/Auth/Login")));
const VerifyOTP = Loadable(lazy(() => import("./pages/Auth/VerifyOTP.jsx")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard/index.js")));
const MainLayout = Loadable(
  lazy(() => import("./components/layout/MainLayout.jsx"))
);

// Employee
const UserList = Loadable(lazy(() => import("./pages/User/index.js")));
const AddEmployee = Loadable(lazy(() => import("./pages/User/_employee/_addEmployee/index.js")));
const ViewEmployee = Loadable(lazy(() => import("./pages/User/_employee/_viewEmployee/index.js")));
const EditEmployee = Loadable(lazy(() => import("./pages/User/_employee/_editEmployee/index.js")));

//Agent
const AddAgent = Loadable(lazy(() => import("./pages/User/_agent/_addAgent/index.js")));
const ViewAgent = Loadable(lazy(() => import("./pages/User/_agent/_viewAgent/index.js")));
const EditAgent = Loadable(lazy(() => import("./pages/User/_agent/_editAgent/index.js")));



//contract
const ContractList = Loadable(lazy(() => import("./pages/Contracts/index.js")));
const AddContract = Loadable(lazy(() => import("./pages/Contracts/_addContract/index.js")));
const ViewEditContract = Loadable(lazy(() => import("./pages/Contracts/_contractVIewEdit/index.js")));

// Merchants
const MerchantsList = Loadable(lazy(() => import("./pages/Merchants/index.js")));
const ViewMerchant = Loadable(lazy(() => import("./pages/Merchants/ViewMerchant/index.js")));

// Notification
const NotificationList = Loadable(lazy(() => import("./pages/Notification/index.js")));
const AddNotification = Loadable(lazy(() => import("./pages/Notification/_addNotification/index.js")));
const ViewNotification = Loadable(lazy(() => import("./pages/Notification/_viewNotification/index.js")));

// Reports
const Reports = Loadable(lazy(() => import("./pages/Report/index.js")));

// Commission
const CommissionList = Loadable(lazy(() => import("./pages/Commissions/index.js")));
const AddCommission = Loadable(lazy(() => import("./pages/Commissions/_addCommission/index.js")));
const ViewCommission = Loadable(lazy(() => import("./pages/Commissions/_viewCommission/index.js")));
const ViewAgentCommission = Loadable(lazy(() => import("./pages/Commissions/_viewAgentCommission/index.js")));

// Deleted
const DeltedList = Loadable(lazy(() => import("./pages/Deleted/index.js")));

// My Account
const MyAccount = Loadable(lazy(() => import("./pages/MyAccount/index.js")));

// chats
const Chats = Loadable(lazy(() => import("./pages/Chats/index.js")));



const Forget = Loadable(lazy(() => import("./pages/Auth/ForgetPassword.js")));
const ResetForgetPassword = Loadable(lazy(() => import("./pages/Auth/ResetForgetPassword.js")));



function App() {

  const user = useSelector((state) => state?.auth?.user);

  let dispatch = useDispatch();


  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <PublicRoute>
          <PublicLayout />
        </PublicRoute>
      ),
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "/auth/verifyOTP",
          element: <VerifyOTP />,
        },
        {
          path: "/auth/forget-password",
          element: <Forget />,
        },

        {
          path: "/forget-password/:code",
          element: <ResetForgetPassword />,
        },
      ],
    },

    {
      path: "/",
      element: (
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "users",
          element: <UserList />,
        },
        {
          path: "users/add-employee",
          element: <AddEmployee />,
        },
        {
          path: "users/view-employee/:adminId",
          element: <ViewEmployee />,
        },
        {
          path: "users/edit-employee/:adminId",
          element: <EditEmployee />,
        },
        {
          path: "users/add-agent",
          element: <AddAgent />,
        },
        {
          path: "users/view-agent/:agentId",
          element: <ViewAgent />,
        },
        {
          path: "users/edit-agent/:agentId",
          element: <EditAgent />,
        },
        {
          path: "contracts",
          element: <ContractList />,
        },
        {
          path: "contracts/add-contract",
          element: <AddContract />,
        },
        {
          path: "contract/:type/:contractId",
          element: <ViewEditContract />,
        },
        {
          path: "merchants",
          element: <MerchantsList />,
        },
        {
          path: "merchant/view/:merchantId",
          element: <ViewMerchant />,
        },
        {
          path: "notifications",
          element: <NotificationList />,
        },
        {
          path: "notification-add",
          element: <AddNotification />,
        },
        {
          path: 'notification-view/:notificationId',
          element: <ViewNotification />,
        },
        {
          path: 'reports',
          element: <Reports />,
        },
        {
          path: 'commissions',
          element: <CommissionList />,
        },
        {
          path: 'commission-add',
          element: <AddCommission />,
        },
        {
          path: 'commission-view/:commissionId',
          element: <ViewCommission />,
        },
        {
          path: 'commission-agent-view/:commissionId',
          element: <ViewAgentCommission />,
        },
        {
          path: 'deleted',
          element: <DeltedList />,
        },
        {
          path: 'my-account',
          element: <MyAccount />,
        },
        {
          path: 'chats',
          element: <Chats />,
        },


      ],
    },
    // esign
    {
      path: "/contract/esign/:code",
      element: <Esign />,
    },
  ]);

  const api = user?.role == 'agent' ? _agentViewApi : _employeeViewApi
  const id = user?.role == 'agent' ? user?.agentId : user?.adminId
  const key = user?.role == 'agent' ? 'viewAgent' : 'viewEmployee'



  useQuery({
    queryKey: [key, id],
    queryFn: () => api(id),
    onSuccess: (data) => {
      dispatch(login({ ...user, ...data.data }));
    },
    retry: false,
    enabled: id ? true : false,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
  })


  return <>
    <DesktopWarning />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
      progress={undefined}
    />
    <RouterProvider router={router} />
  </>
}

export default App;
