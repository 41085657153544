import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMessage:{},
}

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    selectedMessage: (state, action) => {
      state.selectedMessage = action?.payload ?? {};
    },
  },
});

export const { selectedMessage } = chat.actions;
export default chat.reducer;


