import axios from 'axios';
import { store } from '../_redux/store';
import { toast } from 'react-toastify';
import { login, logout } from '../_redux/_slice/authReducer';

const API_AXIOS = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

API_AXIOS.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth?.user?.authToken || null;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['ngrok-skip-browser-warning'] = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



API_AXIOS.interceptors.response.use(
  (response) => {
    return response;
  },


  async (error) => {
    let message = 'Something went wrong';
    if (error.response) {
console.log({error});

      if (error.response.data?.message === 'Unauthorized' || error.response.data?.message === 'Invalid token') {
        refreshApi(error)
      } else {
        if (error?.response.config.responseType === 'blob') {
          try {
            const text = await error?.response.data.text();
            const errorData = JSON.parse(text);
            message = errorData?.message || 'Internal Server Error';
          } catch (error) {
            console.error('Error parsing response:', error);
          }
        } else {
          message = error.response.data?.message || 'Internal Server Error';
        }
      }






    } else if (error?.request) {
      message = error?.response?.data?.message || 'Network error. Please try again later.';
    }
    toast.error(message);
    return Promise.reject(error);
  }
)
export { API_AXIOS }




const refreshApi=async(error)=>{
  try {
    const user = store.getState()?.auth?.user;
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/re-auth`, { refreshToken: user?.refreshToken });
    
    // Update the store with the new token if the refresh is successful
    const newToken = response.data;
    console.log('response',newToken?.data);
    
    store.dispatch(login({...user, refreshToken:newToken.data?.refreshToken, authToken:newToken.data?.authToken}));
    
    error.config.headers.Authorization = `Bearer ${newToken?.authToken}`;
    console.log('error.config',error.config);
    return API_AXIOS.request(error.config);



  } catch (refreshError) {
    toast.error('Session expired. Please log in again.');
    store.dispatch(logout());
  }

}